
// ReceiptCard.js
import React, { useRef } from 'react';
import './ReceiptCard.css';
import logo from '../assets/logo.png';
import scissor from '../assets/scissor-v.webp';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ReceiptCard = ({ receiptData, t }) => {
  const printRef = useRef();
  // Error handling for missing data
  if (!receiptData?.payment) {
    return (
      <div className="receipt-page-container">
        <div className="error-message">
          {t('receiptDataNotFound') || 'Receipt data not found.'}
        </div>
      </div>
    );
  }

  const { payment, message } = receiptData;
  const person = payment.person; // Person is now nested in payment

  if (!person) {
    return (
      <div className="receipt-page-container">
        <div className="error-message">
          {t('personDataNotFound') || 'Person data not found.'}
        </div>
      </div>
    );
  }

  const paymentDate = new Date(payment.created_at).toLocaleString();

  // Calculate validity date (1 year from payment date)
  const validityDate = new Date(payment.created_at);
  validityDate.setFullYear(validityDate.getFullYear() + 1);
  const validUntil = validityDate.toISOString().split('T')[0];





  // Function to handle PDF download
  const handleDownloadPdf = () => {
    const input = printRef.current;
    html2canvas(input, { scale: 2, useCORS: true })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'cm',
          format: [21, 29.7], // A4 size
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`receipt_${payment.id}.pdf`);
      })
      .catch((err) => {
        console.error('Failed to generate PDF', err);
      });
  };


  return (
    <div>
    <button className="download-button" onClick={handleDownloadPdf}>
        {t('downloadPdf') || 'Download as PDF'}
      </button>
    <div className="receipt-page-container" ref={printRef}>
      {/* Download PDF Button */}
      
      {/* Receipt Header */}
      <hr className="receipt-divider" />
      <div className="receipt-header">
        <img src={logo} alt="Logo" className="receipt-logo" />
        <h1 className="receipt-title">{t('paymentReceipt') || 'Payment Receipt'}</h1>
      </div>
      <hr className="receipt-divider" />

      {/* Receipt Details */}
      <div className="receipt-details">
        {/* Payment details */}
        <div className="receipt-row">
          <strong>{t('paymentId') || 'Payment ID'}:</strong>
          <span>{payment.id}</span>
        </div>
        <div className="receipt-row">
          <strong>{t('razorpayOrderId') || 'Razorpay Order ID'}:</strong>
          <span>{payment.razorpay_order_id}</span>
        </div>
        <div className="receipt-row">
          <strong>{t('razorpayPaymentId') || 'Razorpay Payment ID'}:</strong>
          <span>{payment.razorpay_payment_id}</span>
        </div>
        <div className="receipt-row">
          <strong>{t('amount') || 'Amount'}:</strong>
          <span>₹{payment.amount} {payment.currency}</span>
        </div>
        <div className="receipt-row">
          <strong>{t('subscriptionType') || 'Subscription Type'}:</strong>
          <span>{t(payment.subscription_type)}</span>
        </div>
        <div className="receipt-row">
          <strong>{t('status') || 'Status'}:</strong>
          <span>{t(payment.status)}</span>
        </div>
        <div className="receipt-row">
          <strong>{t('purpose') || 'Purpose'}:</strong>
          <span>{t(payment.purpose)}</span>
        </div>
        <div className="receipt-row">
          <strong>{t('paymentDate') || 'Payment Date'}:</strong>
          <span>{paymentDate}</span>
        </div>
        {message && (
          <div className="receipt-row message">
            <strong>{t('message') || 'Message'}:</strong>
            <span>{message}</span>
          </div>
        )}
      </div>
      <hr className="receipt-divider" />
      <div className="receipt-header">
        <img src={logo} alt="Logo" className="receipt-logo" />
        <h1 className="receipt-title">{t('idcard_title') || 'ID Card'}</h1>
      </div>
      <hr className="receipt-divider" />

      {/* ID Cards Section */}
      <div className="id-cards">
        {/* Front ID Card */}
        <div className="id-card front">
          <div className="id-card-header">
            <img src={logo} alt="Logo" className="id-card-logo" />
            <h2 className="id-card-title">{t('title') || 'Thogata Veera Kshatriya Sangham'}</h2>
            <div className="id-card-header-info">
              <p><strong>{t('membershipId') || 'Membership ID'}:</strong> {person.id}</p>
              <p><strong>{t('validUntil') || 'Valid Until'}:</strong> {validUntil}</p>
            </div>
          </div>
          <div className="id-card-body">
            <div className="id-card-main-section">
              <div className="id-card-photo">
                {person.avatar ? (
                  <img src={person.avatar} alt={t('photo') || 'Photo'} className="member-photo" />
                ) : (
                  <div className="photo-placeholder">{t('noPhoto') || 'No Photo'}</div>
                )}
              </div>
              <div className="id-card-front-main">
                <p><strong>{t('name') || 'Name'}:</strong> {person.name} {person.surname}</p>
                <p><strong>{t('sOrWifeOrDaughterOf') || 'Relation'}:</strong> {person.relative_name}</p>

              <p><strong>{t('gotra') || 'Gotra'}:</strong> {person.gotra}</p>
              <p><strong>{t('mobileNumber') || 'Mobile'}:</strong> {person.mobile}</p>
              <p><strong>{t('emailId') || 'Email'}:</strong> {person.email}</p>
            </div>
              </div>
            {/* This should come below with smaller font*/}
            
          </div>
        </div>

        {/* Scissor Mark */}
        <img src={scissor} alt="Scissor Mark" className="scissor-mark" />
       {/* Back ID Card */}
        <div className="id-card back">
          <div className="id-card-header">
            <h2 className="id-card-title">{t('title') || 'Thogata Veera Kshatriya Sangham'}</h2>
            <div className="ribbon"></div>
          </div>
          <div className="id-card-body">
            {/* Row containing the two columns */}
            <div className="id-card-section-row">
              <div className="id-card-section-1a">
                <p><strong>{t('dateOfBirth') || 'DOB'}:</strong> {person.date_of_birth}</p>
                <p><strong>{t('sex') || 'Sex'}:</strong> {t(person.sex)}</p>
                <p><strong>{t('marriageStatus') || 'Marital Status'}:</strong> {t(person.marriage_status)}</p>
              </div>
              <div className="id-card-section-1b">
                <p><strong>{t('bloodGroup') || 'Blood Group'}:</strong> {person.blood_group}</p>
                <p><strong>{t('education') || 'Education'}:</strong> {person.education}</p>
                <p><strong>{t('occupation') || 'Occupation'}:</strong> {person.occupation}</p>
              </div>
            </div>
            {/* Full-width address section */}
            <div className="id-card-section-2">
              <p><strong>{t('address') || 'Address'}:</strong></p> 
              <p>{person.door_number}, {person.street}, {person.mandal} (Mandal), </p>
              <p> {person.district} (Dist), {person.state} - {person.pincode}</p>
            </div>
          </div>
          {/* Footer */}
          <div className="id-card-footer">
            <p><strong>{t('contact')}:</strong> contact@thogata.co.in</p>
            <p><strong>{t('website')}:</strong> {t('web') || 'www.thogata.co.in'}</p>
          </div>
        </div>         

      </div>
    </div>
    </div>
  );
};

export default ReceiptCard;
