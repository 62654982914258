// src/components/DistrictSelector.js
import React, { useEffect, useState } from 'react';
import api from '../api';

import { Label } from './ui/label';

const DistrictSelector = ({ selectedState, selectedDistrict, setSelectedDistrict }) => {
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedState) {
      const fetchDistricts = async () => {
        try {
          setLoading(true);
          setError(null);
          const response = await api.raw.get('/api/districts', { 
            params: { state_id: selectedState } 
          });
          setDistricts(response.data);
        } catch (err) {
          console.error('Error fetching districts:', err);
          setError('Failed to load districts');
        } finally {
          setLoading(false);
        }
      };

      fetchDistricts();
    } else {
      setDistricts([]);
      setSelectedDistrict('');
    }
  }, [selectedState, setSelectedDistrict]);

  if (loading) {
    return <div className="space-y-2">
      <Label>District</Label>
      <div className="w-full p-2 border rounded-md text-gray-500">Loading districts...</div>
    </div>;
  }

  if (error) {
    return <div className="space-y-2">
      <Label>District</Label>
      <div className="w-full p-2 border rounded-md text-red-500">{error}</div>
    </div>;
  }

  return (
    <div className="space-y-2">
      <Label>District</Label>
      <select 
        value={selectedDistrict}
        onChange={(e) => setSelectedDistrict(e.target.value)}
        className="w-full p-2 border rounded-md"
        disabled={!selectedState}
      >
        <option value="">Select District</option>
        {districts.map((district) => (
          <option key={district.id} value={district.id}>
            {district.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DistrictSelector;