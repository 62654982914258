// src/components/Header.js
import React from 'react';
import logo from '../assets/logo.png';
import LanguageSelector from './LanguageSelector';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Header = ({ title, lang, setLang }) => {
  const navigate = useNavigate();

  const handleDonate = () => {
    navigate('/donate'); // Navigate to the donation page
  };

  return (
    <header className="header">
      <div className="header-content">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="title">{title}</h1>
      </div>
      <div className="header-actions">
        <LanguageSelector lang={lang} setLang={setLang} />
        {/* <Button
          onClick={handleDonate}
          className="ml-4 bg-green-500 hover:bg-green-600"
        >
          DONATE NOW
        </Button> */}
      </div>
    </header>
  );
};

export default Header;
