// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer bg-gray-800 text-white py-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="mb-2 md:mb-0">
          &copy; {new Date().getFullYear()} Thogata Veera Kshatriya Sangham. All rights reserved.
        </div>
        <div className="flex space-x-4">
          <Link to="/terms" className="hover:underline">
            Terms &amp; Conditions
          </Link>
          <Link to="/privacy" className="hover:underline">
            Privacy Policy
          </Link>
          <Link to="/refund-cancellation" className="hover:underline">
            Refund &amp; Cancellation Policy
          </Link>
          <Link to="/return" className="hover:underline">
            Return Policy
          </Link>
          <Link to="/shipping" className="hover:underline">
            Shipping Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
