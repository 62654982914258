// // // ./FormField.js
// // import React from 'react';

// // const FormField = ({ 
// //   name, 
// //   label, 
// //   value, 
// //   onChange, 
// //   type = 'text', 
// //   options = [],
// //   containerClassName = ''
// // }) => {
// //   return (
// //     <div className={`flex flex-col space-y-1.5 ${containerClassName}`}>
// //       <label 
// //         htmlFor={name} 
// //         className="text-sm font-medium text-gray-700"
// //       >
// //         {label}
// //       </label>
// //       {type === 'select' ? (
// //         <select
// //           id={name}
// //           name={name}
// //           value={value}
// //           onChange={onChange}
// //           className="block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm transition-colors"
// //           required
// //         >
// //           <option value="">{/* Placeholder */}</option>
// //           {options.map((option) => (
// //             <option key={option.value} value={option.value}>
// //               {option.label}
// //             </option>
// //           ))}
// //         </select>
// //       ) : (
// //         <input
// //           id={name}
// //           name={name}
// //           type={type}
// //           value={value}
// //           onChange={onChange}
// //           className="block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm transition-colors"
// //           required
// //         />
// //       )}
// //     </div>
// //   );
// // };

// // export default FormField;
// // FormField.js
// import React from 'react';

// const FormField = ({ 
//   name, 
//   label, 
//   value, 
//   onChange, 
//   type = 'text', 
//   options = [],
//   containerClassName = ''
// }) => {
//   return (
//     <div className={`flex flex-col space-y-1.5 ${containerClassName}`}>
//       <label 
//         htmlFor={name} 
//         className="text-sm font-medium text-gray-700"
//       >
//         {label}
//       </label>
//       {type === 'select' ? (
//         <select
//           id={name}
//           name={name}
//           value={value}
//           onChange={onChange}
//           className="block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm transition-colors"
//           required
//         >
//           <option value="">{/* Placeholder */}</option>
//           {options.map((option) => (
//             <option key={option.value} value={option.value}>
//               {option.label}
//             </option>
//           ))}
//         </select>
//       ) : (
//         <input
//           id={name}
//           name={name}
//           type={type}
//           value={value}
//           onChange={onChange}
//           className="block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm transition-colors"
//           required
//         />
//       )}
//     </div>
//   );
// };

// export default FormField;
// FormField.js
import React from 'react';
import { Label } from './ui/label';
const FormField = ({ 
  name, 
  label, 
  value, 
  onChange, 
  type = 'text', 
  options = [],
  containerClassName = '',
  layout = 'vertical', // 'vertical' or 'horizontal'
}) => {
  const isHorizontal = layout === 'horizontal';

  return (
    <div className={`${isHorizontal ? 'flex items-center' : 'flex flex-col space-y-1.5'} ${containerClassName}`}>
      {/* <label 
        htmlFor={name} 
        className={`${isHorizontal ? 'w-1/3 text-right pr-4 text-sm font-medium text-gray-700' : 'text-sm font-medium text-gray-700'}`}
      >
        
        {label}
      </label> */}

      <Label>{label}</Label>
      {type === 'select' ? (
        <select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          className={`block ${isHorizontal ? 'w-2/3' : 'w-full'} rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm transition-colors`}
          required
        >
          <option value="">{/* Placeholder */}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          id={name}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          className={`block ${isHorizontal ? 'w-2/3' : 'w-full'} rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm transition-colors`}
          required
        />
      )}
    </div>
  );
};

export default FormField;
