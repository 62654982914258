// src/components/StateSelector.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import { Label } from './ui/label';

const StateSelector = ({ selectedState, setSelectedState }) => {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await api.raw.get('/api/states');
        setStates(response.data);
      } catch (err) {
        console.error('Error fetching states:', err);
        setError('Failed to load states');
      } finally {
        setLoading(false);
      }
    };

    fetchStates();
  }, []);

  if (loading) {
    return (
      <div className="space-y-2">
        <Label>State</Label>
        <div className="w-full p-2 border rounded-md text-gray-500">Loading states...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="space-y-2">
        <Label>State</Label>
        <div className="w-full p-2 border rounded-md text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <Label>State</Label>
      <select
        value={selectedState || ''}
        onChange={(e) => setSelectedState(e.target.value)}
        className="w-full p-2 border rounded-md"
      >
        <option value="">Select State</option>
        {states.map((state) => (
          <option key={state.id} value={state.id}>
            {state.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StateSelector;