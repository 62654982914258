// IDCard.js
import React, { useRef } from 'react';
import { Button } from '../components/ui/button';
import { useReactToPrint } from 'react-to-print';
import logo from '../assets/logo.png';
import './IDCard.css';

const IDCard = ({ userData, t }) => {
  const cardRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => cardRef.current,
  });

  const mockData = {
    name: 'John Doe',
    dateOfBirth: '1990-01-01',
    membershipId: 'TVKS123456',
    address: '123, Main Street\nTownsville,\nDistrict 9\nStateville - 123456',
  };

  const data = userData || mockData;

  return (
    <div className="id-card-container">
      <div ref={cardRef} className="id-card">
        {/* Front Side */}
        <div className="id-card-side id-card-front">
          <div className="id-card-header">
            <img src={logo} alt="Logo" className="id-card-logo" />
            <h2>{t('title')}</h2>
          </div>
          <div className="id-card-body">
            <div className="id-card-photo">
              <div className="photo-placeholder">{t('noPhoto')}</div>
            </div>
            <div className="id-card-details">
              <p><strong>{t('name')}:</strong> {data.name}</p>
              <p><strong>{t('membershipId')}:</strong> {data.membershipId}</p>
              <p><strong>{t('dateOfBirth')}:</strong> {data.dateOfBirth}</p>
            </div>
          </div>
        </div>
        
        <div className="scissor-line">
          <span>✂ - - - - - - - - - - - - - - - - - - - - - - - - - - - </span>
        </div>

        {/* Back Side */}
        <div className="id-card-side id-card-back">
          <div className="id-card-header">
            <h2>{t('title')}</h2>
          </div>
          <div className="id-card-body">
            <div className="id-card-address">
              <h3>{t('address')}</h3>
              <p>{data.address}</p>
            </div>
            <div className="id-card-footer">
              <p><strong>{t('issuedBy')}:</strong> {t('issuingAuthority')}</p>
              <p><strong>{t('issueDate')}:</strong> {new Date().toLocaleDateString()}</p>
            </div>
          </div>
          <img src={logo} alt="Logo" className="id-card-logo-back" />
        </div>
      </div>
      <Button onClick={handlePrint}>{t('downloadIDCard')}</Button>
    </div>
  );
};

export default IDCard;