// src/components/MandalSelector.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import { Label } from './ui/label';

const MandalSelector = ({ selectedDistrict, selectedMandal, setSelectedMandal }) => {
  const [mandals, setMandals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedDistrict) {
      const fetchMandals = async () => {
        try {
          setLoading(true);
          setError(null);
          const response = await api.raw.get('/api/mandals', { 
            params: { district_id: selectedDistrict } 
          });
          setMandals(response.data);
        } catch (err) {
          console.error('Error fetching mandals:', err);
          setError('Failed to load mandals');
        } finally {
          setLoading(false);
        }
      };

      fetchMandals();
    } else {
      setMandals([]);
      setSelectedMandal('');
    }
  }, [selectedDistrict, setSelectedMandal]);

  if (loading) {
    return <div className="space-y-2">
      <Label>Mandal</Label>
      <div className="w-full p-2 border rounded-md text-gray-500">Loading mandals...</div>
    </div>;
  }

  if (error) {
    return <div className="space-y-2">
      <Label>Mandal</Label>
      <div className="w-full p-2 border rounded-md text-red-500">{error}</div>
    </div>;
  }

  return (
    <div className="space-y-2">
      <Label>Mandal</Label>
      <select 
        value={selectedMandal}
        onChange={(e) => setSelectedMandal(e.target.value)}
        className="w-full p-2 border rounded-md"
        disabled={!selectedDistrict}
      >
        <option value="">Select Mandal</option>
        {mandals.map((mandal) => (
          <option key={mandal.id} value={mandal.id}>
            {mandal.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MandalSelector;