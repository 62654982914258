// // File: src/components/ui/radio-group.js
// import React from 'react';

// export const RadioGroup = React.forwardRef(({ className, ...props }, ref) => {
//   return (
//     <div ref={ref} className={className} {...props} />
//   );
// });
// RadioGroup.displayName = "RadioGroup";

// export const RadioGroupItem = React.forwardRef(({ className, ...props }, ref) => {
//   return (
//     <input
//       type="radio"
//       className={`h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
//       ref={ref}
//       {...props}
//     />
//   );
// });
// RadioGroupItem.displayName = "RadioGroupItem";
import React, { createContext, useContext, useState } from 'react';

const RadioGroupContext = createContext();

export const RadioGroup = React.forwardRef(({ className, children, value, onValueChange, ...props }, ref) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <RadioGroupContext.Provider value={{ selectedValue, onChange: handleChange }}>
      <div ref={ref} className={className} {...props}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
});
RadioGroup.displayName = "RadioGroup";

export const RadioGroupItem = React.forwardRef(({ className, value, ...props }, ref) => {
  const { selectedValue, onChange } = useContext(RadioGroupContext);

  return (
    <input
      type="radio"
      className={`h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      checked={selectedValue === value}
      onChange={() => onChange(value)}
      value={value}
      {...props}
    />
  );
});
RadioGroupItem.displayName = "RadioGroupItem";