import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { AlertTriangle } from 'lucide-react';

const SimpleDonate = () => {
  // Replace with your actual Razorpay key
  const RAZORPAY_KEY = process.env.RAZORPAY_KEY_ID ;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    amount: '1',
    email: 'narun.iitb@gmail.com',
    name: 'Arun',
    phone: '8800197778',
  });
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const showError = (message) => {
    setError(message);
    setTimeout(() => setError(''), 5000);
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    try {
      setLoading(true);
      setError('');
      
      const res = await initializeRazorpay();
      
      if (!res) {
        throw new Error('Razorpay SDK failed to load');
      }

      const options = {
        key: 'rzp_live_YpeadRFtCUasRh' ,  // Test key
        amount: (parseFloat(formData.amount) * 100).toString(), // Amount in paise
        currency: "INR",
        name: "Thogata Veera Kshatriya Sangham",
        description: "Donation",
        // image: logo,  // Uncomment and update path once you have the logo
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.phone
        },
        theme: {
          color: "#3399cc"
        },
        handler: function(response) {
          alert('Payment successful! Payment ID: ' + response.razorpay_payment_id);
          setFormData({
            amount: '',
            email: '',
            name: '',
            phone: '',
          });
          setLoading(false);
        },
        modal: {
          ondismiss: function() {
            setLoading(false);
          }
        }
      };
      console.log(options)

    //   const rzp = new window.Razorpay(options);
      
    //   rzp.on('payment.failed', function (response) {
    //     showError(response.error.description);
    //     setLoading(false);
    //   });

    //   rzp.open();
      
    } catch (error) {
      showError(error.message);
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (!formData.amount || isNaN(formData.amount) || Number(formData.amount) <= 0) {
      return false;
    }
    if (!formData.email || !formData.email.includes('@')) {
      return false;
    }
    if (!formData.name || formData.name.trim().length < 2) {
      return false;
    }
    if (!formData.phone || formData.phone.length < 10) {
      return false;
    }
    return true;
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <Card>
        <CardHeader>
          <h2 className="text-2xl font-bold text-center">Donate to Thogata Veera Kshatriya Sangham</h2>
        </CardHeader>
        <CardContent>
          {error && (
            <div className="mb-4 p-3 bg-red-100 border border-red-400 rounded flex items-center">
              <AlertTriangle className="w-5 h-5 text-red-500 mr-2" />
              <span className="text-red-700">{error}</span>
            </div>
          )}

          <div className="space-y-4">
            <div>
              <Input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                placeholder="Amount (₹)"
                min="1"
              />
            </div>

            <div>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Full Name"
                minLength={2}
              />
            </div>

            <div>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email Address"
              />
            </div>

            <div>
              <Input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Mobile Number"
                minLength={10}
                maxLength={10}
              />
            </div>

            <Button
              onClick={handlePayment}
              disabled={loading || !validateForm()}
              className="w-full"
            >
              {loading ? 'Processing...' : 'Donate Now'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SimpleDonate;