
import React, { useState, createContext, useContext } from 'react';

const SelectContext = createContext();

const Select = React.forwardRef(({ children, onValueChange, ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ value: props.value || '', label: '' });

  const handleSelect = (value, label) => {
    setSelectedOption({ value, label });
    if (onValueChange) {
      onValueChange(value);
    }
    setIsOpen(false);
  };

  return (
    <SelectContext.Provider value={{ isOpen, setIsOpen, selectedOption, handleSelect }}>
      <div ref={ref} className="select-container" {...props}>
        {children}
      </div>
    </SelectContext.Provider>
  );
});
Select.displayName = "Select";

const SelectTrigger = ({ children }) => {
  const { isOpen, setIsOpen, selectedOption } = useContext(SelectContext);
  return (
    <div className="select-trigger" onClick={() => setIsOpen(!isOpen)}>
      {React.Children.map(children, child => 
        React.cloneElement(child, { selectedOption })
      )}
      <span className={`arrow ${isOpen ? 'up' : 'down'}`}></span>
    </div>
  );
};
SelectTrigger.displayName = "SelectTrigger";

const SelectValue = ({ placeholder, selectedOption }) => {
  return (
    <span className="select-value">
      {selectedOption.label || placeholder}
    </span>
  );
};
SelectValue.displayName = "SelectValue";

const SelectContent = ({ children }) => {
  const { isOpen } = useContext(SelectContext);
  if (!isOpen) return null;
  return (
    <div className="select-content">
      {children}
    </div>
  );
};
SelectContent.displayName = "SelectContent";

const SelectItem = ({ children, value }) => {
  const { handleSelect } = useContext(SelectContext);
  return (
    <div className="select-item" onClick={() => handleSelect(value, children)}>
      {children}
    </div>
  );
};
SelectItem.displayName = "SelectItem";

export { Select, SelectTrigger, SelectValue, SelectContent, SelectItem };