// export const translations = {
//   en: {
//     title: "Thogata Veera Kshatriya Sangham",
//     web:"https://www.thogata.co.in/",
//     email:"info@thogata.co.in",
//     welcome: "Om Sri Chaudeshwari Matayai Namah Welcome to Togata Veera Kshatriya Sangam!",
//     joinMessage: "Welcome to the Togata Veera Kshatriya community. A warm welcome to every member associated with the Andhra Pradesh Togata Veera Kshatriya Sangham. Heartfelt greetings to every individual who has committed to the strengthening of our community and association. We assure you that your contributions, whether in donations or membership fees, will be used justly. Please note that these funds will be utilized for the development of the association, bringing community members together, and supporting those in need within the community. You are encouraged to use this opportunity to connect with every member of our association.",
//     subscribe: "Subscribe",
//     name: "Name",
//     surname: "Surname",
//     phone: "Mobile Number",
//     paymentReceipt:"Payment Reciept",
//     paymentId:"Serial Number",
//     validUntil:"Valid",
//     amount:"Amount Paid",
//     razorpayOrderId:"Order Id",
//     razorpayPaymentId:"Payment Id",
//     purpose:"Purpose",
//     paymentDate:"Payment Made On",
//     message:     "Message",


// 'sOrWifeOrDaughterOf': 'S/o or D/o or W/o ',
// 'dateOfBirth': 'Date of Birth',
// 'photo': 'Photo',
// 'takePhoto': 'Take Photo',
// 'crop': 'Crop',
// 'noPhotoSelected': 'No photo selected',
// // In 'en' translations
// 'cancel': 'Cancel',
// // In 'en' translations
// 'membershipId': 'ID',
// 'address': 'Address',
// 'downloadIDCard': 'Download ID Card',
// 'noPhoto': 'No Photo',
// // In 'en' translations
// 'issuedBy': 'Issued By',
// 'issuingAuthority': 'Thogata Veera Kshatriya Sangham',
// 'issueDate': 'Issue Date',
// donate: "Donate",
//     donateMessage: "Your contributions help us grow and support our community members. Please consider donating to support our initiatives.",
//     donateNow: "Donate Now",

//     email: "Email Id",
//     gotra: "Gotra",
//     houseNumber: "House Number",
//     streetName: "Village Name/Street Name",
//     mandal: "Mandal/Town",
//     district: "District",
//     state: "State",
//     pinCode: "Pin Code",
//     selectsubscriptionType: "Select Type",
//     subscriptionType: "Subscription Type",
//     donation: "Donation",
//     subscription: "Subscription",
//     maharajaPoshakulu: "Maharaja Poshakulu",
//     rajaPoshakulu: "Raja Poshakulu",
//     saswataSabhyatvam: "Permanant Subscription",
//     yearlySubscription: "Yearly Subscription",
//     sadharanaSabyatvam: "Normal Subscription",
//     submit: "Submit and Proceed to Payment"
//   },
//   te: {
//     title: "తొగట వీర క్షత్రియ సంఘం ",
//     welcome: "ఓం శ్రీ చౌడేశ్వరి మాతయై నమః \n తొగట వీర క్షత్రియ సంఘంనకు స్వాగతం!",
//     joinMessage: "తొగట వీర క్షత్రియ కులబంధువుల కు స్వాగతం.  ఆంధ్రప్రదేశ్ తొగట వీర క్షత్రియ సంఘం తో అనుసంధానమైన ప్రతిఒక్క సభ్యనికి స్వాగతం. మన కులం, సంఘం బలోపేతానికి కంకణం కట్టుకున్న ప్రతిఒక్క కులస్తునికి మనస్ఫూర్తిగా శుభాభివందనాలు. మీ విరాళాలు గాని మీ సభ్యత్వ రుసుమునకు గాని పూర్తిగా న్యాయం చేస్తామని మాట ఇస్తున్నాము. ఈ విరాళాలు సంఘ అభివృద్ధికి, కులస్తులను,ఏకతాటిమీద తీసుకు రావడానికి మరియు అవసరంలో వున్నా కులస్తులను ఆదుకోవడానికి వినియోగించడం జరుగుతుంది అని గమనించగలరు.\n మీరు మన సంఘంలోని ప్రతిఒక్క సభ్యునితో కూడా అనుసంధానికి ఈ అవకాశాన్ని వినియోగించుకోగలరు. ",
//     subscribe: "సభ్యత్వ నమోదు పత్రం",
//     name: "పేరు",
//     surname: "ఇంటి పేరు",
//     phone: "మొబైల్ నంబర్",

// 'sOrWifeOrDaughterOf': 'తండ్రి/భర్త పేరు',
// 'dateOfBirth': 'పుట్టిన తేది',
// 'photo': 'ఫోటో',
// 'takePhoto': 'ఫోటో తీసుకోండి',
// 'crop': 'క్రాప్ చేయండి',
// 'noPhotoSelected': 'ఫోటో ఎంపిక చేయబడలేదు',
// // In 'te' translations
// 'cancel': 'రద్దు చేయండి',
// // In 'te' translations
// 'membershipId': 'సభ్యత్వ ID',
// 'address': 'చిరునామా',
// 'downloadIDCard': 'ID కార్డు డౌన్‌లోడ్ చేయండి',
// 'noPhoto': 'ఫోటో లేదు',
// donate: "దానం చేయండి",
//     donateMessage: "మీరు చేసిన దానాలు మాకు అభివృద్ధి చెందటానికి మరియు మా సంఘ సభ్యులను మద్దతు ఇవ్వటానికి సహాయపడతాయి. దయచేసి మా కార్యక్రమాలను మద్దతు ఇవ్వడానికి దానం చేయాలని పరిశీలించండి.",
//     donateNow: "ఇప్పుడు దానం చేయండి",



// // In 'te' translations
// 'issuedBy': 'జారీ చేసినది',
// 'issuingAuthority': 'తొగట వీర క్షత్రియ సంఘం',
// 'issueDate': 'జారీ చేసిన తేది',

//     email: "ఇమెయిల్ ఐడి",
//     gotra: "గోత్రం",
//     houseNumber: "ఇంటి నంబర్",
//     streetName: "గ్రామం పేరు/వీధి పేరు",
//     mandal: "మండలం/పట్టణం",
//     district: "జిల్లా",
//     state: "రాష్ట్రం",
//     pinCode: "పిన్ కోడ్",
//     selectsubscriptionType: "ఎంచుకోండి",
//     subscriptionType: "పోషకులు/సభ్యులు ",
//     donation: "పోషకులు",
//     subscription: "సభ్యత్వం",
//     maharajaPoshakulu: "మహారాజ పోషకులు",
//     rajaPoshakulu: "రాజ పోషకులు",
//     saswataSabhyatvam: "శాశ్వత సభ్యత్వం",
//     yearlySubscription: "వార్షిక సభ్యత్వం",
//     sadharanaSabyatvam: "సాధారణ సభ్యత్వం",
//     submit: "చెల్లింపు పేజీకి వెళ్లండి"
//   }
// };

// translations.js

export const translations = {
  en: {
    paymentReceipt: "Payment Receipt",
    paymentId: "Payment ID",
    razorpayOrderId: "Razorpay Order ID",
    razorpayPaymentId: "Razorpay Payment ID",
    amount: "Amount",
    subscriptionType: "Subscription Type",
    status: "Marriage Status",
    purpose: "Purpose",
    paymentDate: "Payment Date",
    message: "Message",
    title: "Thogata Veera Kshatriya Sangham",
    noPhoto: "No Photo",
    title: "Thogata Veera Kshatriya Sangham",
    web:"https://www.thogata.co.in/",
    email:"info@thogata.co.in",
    welcome: "Om Sri Chaudeshwari Matayai Namah Welcome to Togata Veera Kshatriya Sangam!",
    joinMessage: "Welcome to the Togata Veera Kshatriya community. A warm welcome to every member associated with the Andhra Pradesh Togata Veera Kshatriya Sangham. Heartfelt greetings to every individual who has committed to the strengthening of our community and association. We assure you that your contributions, whether in donations or membership fees, will be used justly. Please note that these funds will be utilized for the development of the association, bringing community members together, and supporting those in need within the community. You are encouraged to use this opportunity to connect with every member of our association.",
    subscribe: "Subscribe",
    name: "Name",
    surname:"Surname",
    sOrWifeOrDaughterOf: "S-D-W/o",
    phone: "Mobile",
    email: "Email Id",
    gotra: "Gotra",
    dateOfBirth: "Birth",
    address: "Address",
    houseNumber: "H No",
    streetName: "Village/Street Name",
    mandal: "Mandal/Town",
    district: "District",
    state: "State",
    pinCode: "Pin Code",
    membershipId: "ID",
    validUntil: "Valid",
    issuingAuthority: "Thogata Veera Kshatriya Sangham",
    organizationSealMessage: "This card is the property of the organization. Any unauthorized use is prohibited.",
    contact: "Contact",
    website: "Website",
    web: "https://www.thogata.co.in/",
    subscriptionSummary: "Subscription Summary",
    subscriptionDetails: "Subscription Details",
    type: "Type",
    proceedToPayment: "Proceed to Payment",
    processing: "Processing...",
    back: "Back",
    paymentVerificationFailed: "Payment verification failed.",
    paymentFailed: "Payment failed.",
    subscriptionSummary: "Subscription Summary",
    sex: "Sex",
    male: "Male",
    female: "Female",
    other: "Other",
    marriageStatus: "Status",
    single: "Single",
    married: "Married",
    divorced: "Divorced",
    widowed: "Widowed",
    bloodGroup: "Blood Group",
    education: "Educationn",
    occupation: "Job",
    subscriptionSummary: "Subscription Summary",
    mobileNumber: "Mobile",
    idcard_title:"ID Card",
    "subscribersList": "Subscribers List",
  "viewSubscribers": "View Subscribers",
  "viewReceipt": "View Receipt",
  "dateJoined": "Date Joined",
  "actions": "Actions",
  "loading": "Loading",
  "backToList": "Back to List",
  downloadPdf:"Download PDF",
  uploadPassportPhoto:".",
  uploadNewPhoto:"Upload NewPhoto",
  acceptCrop:"Accept Crop",
  donation:"Donation",
    subscription:"Subscription",
    selectsubscriptionType:"Subscription Type",
    maharajaPoshakulu:"మహారాజపోషకులు",
    rajaPoshakulu:"రాజపోషకులు",
    sadharanaSabyatvam:"General Subscription",
    saswataSabhyatvam:"Permanant Subscription",
    yearlySubscription:"Yearly Subscription",

  
    // Add other keys as needed
  },
  te: {
    paymentReceipt: "డబ్బు చెల్లింపు రసీదు",
    paymentId: "డబ్బు చెల్లింపు ID",
    razorpayOrderId: "ఆర్డర్ ID",
    razorpayPaymentId: "చెల్లింపు ID",
    amount: "మొత్తం",
    subscriptionType: "సభ్యత్వం రకం",
    status: "వివాహ స్థితి",
    purpose: "ఉద్దేశ్యం",
    paymentDate: "చెల్లింపు తేదీ",
    message: "సందేశం",
    title: "తొగట వీర క్షత్రియ సంఘం",
    noPhoto: "ఫోటో లేదు",
    title: "తొగట వీర క్షత్రియ సంఘం ",
    welcome: "ఓం శ్రీ చౌడేశ్వరి మాతయై నమః \n తొగట వీర క్షత్రియ సంఘంనకు స్వాగతం!",
    joinMessage: "తొగట వీర క్షత్రియ కులబంధువుల కు స్వాగతం.  ఆంధ్రప్రదేశ్ తొగట వీర క్షత్రియ సంఘం తో అనుసంధానమైన ప్రతిఒక్క సభ్యనికి స్వాగతం. మన కులం, సంఘం బలోపేతానికి కంకణం కట్టుకున్న ప్రతిఒక్క కులస్తునికి మనస్ఫూర్తిగా శుభాభివందనాలు. మీ విరాళాలు గాని మీ సభ్యత్వ రుసుమునకు గాని పూర్తిగా న్యాయం చేస్తామని మాట ఇస్తున్నాము. ఈ విరాళాలు సంఘ అభివృద్ధికి, కులస్తులను,ఏకతాటిమీద తీసుకు రావడానికి మరియు అవసరంలో వున్నా కులస్తులను ఆదుకోవడానికి వినియోగించడం జరుగుతుంది అని గమనించగలరు.\n మీరు మన సంఘంలోని ప్రతిఒక్క సభ్యునితో కూడా అనుసంధానికి ఈ అవకాశాన్ని వినియోగించుకోగలరు. ",

    name: "పేరు",
    surname:"ఇంటిపేరు",
    sOrWifeOrDaughterOf: "తండ్రి/భర్త పేరు",
    phone: "మొబైల్ నంబర్",
    email: "ఇమెయిల్ ఐడి",
    gotra: "గోత్రం",
    dateOfBirth: "పుట్టిన తేది",
    address: "చిరునామా",
    houseNumber: "ఇంటి నంబర్",
    streetName: "గ్రామం పేరు/వీధి పేరు",
    mandal: "మండలం/పట్టణం",
    district: "జిల్లా",
    state: "రాష్ట్రం",
    pinCode: "పిన్ కోడ్",
    membershipId: "సభ్యత్వ ID",
    validUntil: "సరైనంతవరకు",
    issuingAuthority: "తొగట వీర క్షత్రియ సంఘం",
    organizationSealMessage: "ఈ కార్డు సంఘానికి చెందినది. అనధికారిక ఉపయోగం నిషేధించబడింది.",
    donation:"దానం",
    subscription:"సభ్యత్వం",
    selectsubscriptionType:"సభ్యత్వం రకం",
    maharajaPoshakulu:"మహారాజపోషకులు",
    rajaPoshakulu:"రాజపోషకులు",
    sadharanaSabyatvam:"సాధారణ సభ్యత్వం",
    saswataSabhyatvam:"శాశ్వత సభ్యత్వం",
    yearlySubscription:"వార్షిక సభ్యత్వం",
    uploadPassportPhoto:".",
    contact: "సంప్రదింపు",
    website: "వెబ్సైట్",
    mobileNumber: "Mobile",
    emailId: "Email",
    web: "https://www.thogata.co.in/",
    subscriptionSummary: "సభ్యత్వ సారాంశం",
    subscribe: "సభ్యత్వం తీసుకోండి",
    subscriptionDetails: "సభ్యత్వ వివరాలు",
    type: "రకం",
    proceedToPayment: "చెల్లించు",
    processing: "చేస్తున్నాను...",
    back: "తిరిగి",
    paymentVerificationFailed: "చెల్లింపు నిర్ధారణ విఫలమైంది.",
    paymentFailed: "చెల్లింపు విఫలమైంది.",
    sex: "లింగం",
    male: "పురుషుడు",
    female: "స్త్రీ",
    other: "ఇతర",
    marriageStatus: "వివాహ స్థితి",
    single: "ఏకైకుడు",
    married: "వివాహితుడు",
    divorced: "విడిపోయిన వారు",
    widowed: "వితంతువు",
    bloodGroup: "రక్త గ్రూపు",
    education: "విద్య",
    occupation: "వృత్తి",
    downloadPdf:"PDFని డౌన్‌లోడ్ చేయండి",
    uploadNewPhoto:"కొత్తఫోటో ఎక్కించు",
    acceptCrop:"అంగీకరించు"
    // Add other keys as needed
  }
};
