// src/components/SubscriptionTypeSelector.js
import React from 'react';
import { Label } from '../components/ui/label';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';

const SubscriptionTypeSelector = ({ t, subscriptionType, setSubscriptionType }) => (
  <div className="mt-6">
    <Label>{t('subscriptionType')}</Label>
    <RadioGroup value={subscriptionType} onValueChange={setSubscriptionType}>
      <div className="flex items-center space-x-2">
        <RadioGroupItem id="donation" value="donation" />
        <Label htmlFor="donation">{t('donation')}</Label>
      </div>
      <div className="flex items-center space-x-2">
        <RadioGroupItem id="subscription" value="subscription" />
        <Label htmlFor="subscription">{t('subscription')}</Label>
      </div>
    </RadioGroup>
  </div>
);

export default SubscriptionTypeSelector;