// src/components/SubscriptionOptionsSelector.js
import React from 'react';
import { Label } from '../components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';

const SubscriptionOptionsSelector = ({ t, subscriptionType, formData, setFormData }) => {
  const handleOptionChange = (value) => {
    
    setFormData({ ...formData, subscriptionOption: value });
    // console.log('Option changed',{ ...formData, subscriptionOption: value })
  };

  return (
    <div className="mt-4">
      <Label>{subscriptionType === 'donation' ? t('donation') : t('subscription')}</Label>
      <Select 
        name="subscriptionOption"
        value={formData.subscriptionOption}
        onValueChange={handleOptionChange}
      >
        <SelectTrigger>
          <SelectValue placeholder={t('selectsubscriptionType')} />
        </SelectTrigger>
        <SelectContent>
          {subscriptionType === 'donation' ? (
            <>
              <SelectItem value="maharajaPoshakulu">{t('maharajaPoshakulu')}</SelectItem>
              <SelectItem value="rajaPoshakulu">{t('rajaPoshakulu')}</SelectItem>
            </>
          ) : (
            <>
              {/* <SelectItem value="saswataSabhyatvam">{t('saswataSabhyatvam')}</SelectItem>
              <SelectItem value="yearlySubscription">{t('yearlySubscription')}</SelectItem> */}
              <SelectItem value="sadharanaSabyatvam">{t('sadharanaSabyatvam')}</SelectItem>
            </>
          )}
        </SelectContent>
      </Select>
    </div>
  );
};

export default SubscriptionOptionsSelector;