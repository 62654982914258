// File: src/components/LanguageSelector.js
import React from 'react';

const LanguageSelector = ({ lang, setLang }) => (
  <div className="language-selector">
    <div className="toggle-switch">
      <input
        type="checkbox"
        id="language-toggle"
        className="toggle-switch-checkbox"
        checked={lang === 'te'}
        onChange={() => setLang(lang === 'en' ? 'te' : 'en')}
      />
      <label className="toggle-switch-label" htmlFor="language-toggle">
        <span className="toggle-switch-inner"></span>
        <span className="toggle-switch-switch"></span>
      </label>
    </div>
  </div>
);

export default LanguageSelector;