
// // import React, { useState } from 'react';
// // import Cropper from 'react-easy-crop';
// // import { Button } from './ui/button';
// // import { FiUpload } from 'react-icons/fi';

// // function PhotoUpload({ onPhotoCropped, t }) {
// //   const [imageSrc, setImageSrc] = useState(null);
// //   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
// //   const [zoom, setZoom] = useState(1);
// //   const [crop, setCrop] = useState({ x: 0, y: 0 });
// //   const [croppedImageUrl, setCroppedImageUrl] = useState(null);
// //   const [cropped, setCropped] = useState(false);

// //   const onFileChange = async (e) => {
// //     if (e.target.files && e.target.files.length > 0) {
// //       const fileReader = new FileReader();
// //       fileReader.onload = () => {
// //         setImageSrc(fileReader.result);
// //         setCropped(false);
// //         setZoom(1);
// //       };
// //       fileReader.readAsDataURL(e.target.files[0]);
// //     }
// //   };

// //   const onCropComplete = (croppedArea, croppedAreaPixels) => {
// //     setCroppedAreaPixels(croppedAreaPixels);
// //   };

// //   const acceptCrop = async () => {
// //     const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
// //     setCroppedImageUrl(croppedImage);
// //     setCropped(true);
// //     onPhotoCropped(croppedImage); // Pass the cropped image back to the parent component
// //   };

// //   const getCroppedImg = (imageSrc, croppedAreaPixels) => {
// //     return new Promise((resolve, reject) => {
// //       const image = new Image();
// //       image.src = imageSrc;
// //       image.crossOrigin = 'anonymous';
// //       image.onload = () => {
// //         const canvas = document.createElement('canvas');
  
// //         // Calculate aspect ratio
// //         const aspectRatio = croppedAreaPixels.width / croppedAreaPixels.height;
  
// //         // Set max dimensions
// //         let outputWidth = croppedAreaPixels.width;
// //         let outputHeight = croppedAreaPixels.height;
  
// //         if (outputWidth > 300 || outputHeight > 300) {
// //           if (aspectRatio > 1) {
// //             // Width is greater than height
// //             outputWidth = 300;
// //             outputHeight = 300 / aspectRatio;
// //           } else {
// //             // Height is greater than width
// //             outputHeight = 300;
// //             outputWidth = 300 * aspectRatio;
// //           }
// //         }
  
// //         canvas.width = outputWidth;
// //         canvas.height = outputHeight;
// //         const ctx = canvas.getContext('2d');
  
// //         ctx.drawImage(
// //           image,
// //           croppedAreaPixels.x,
// //           croppedAreaPixels.y,
// //           croppedAreaPixels.width,
// //           croppedAreaPixels.height,
// //           0,
// //           0,
// //           outputWidth,
// //           outputHeight
// //         );
  
// //         const base64Image = canvas.toDataURL('image/png');
// //         resolve(base64Image);
// //       };
// //       image.onerror = (error) => {
// //         reject(error);
// //       };
// //     });
// //   };
  

// //   const resetImage = () => {
// //     setImageSrc(null);
// //     setCroppedAreaPixels(null);
// //     setZoom(1);
// //     setCrop({ x: 0, y: 0 });
// //     setCroppedImageUrl(null);
// //     setCropped(false);
// //   };

// //   return (
// //     <div className="space-y-4">
// //       {!imageSrc && (
// //         <div>
// //           <label htmlFor="photo-upload" className="flex items-center cursor-pointer">
// //             <FiUpload className="mr-2" />
// //             <span>{t('uploadPhoto')}</span>
// //           </label>
// //           <input
// //             type="file"
// //             accept="image/*"
// //             id="photo-upload"
// //             onChange={onFileChange}
// //             style={{ display: 'none' }}
// //           />
// //         </div>
// //       )}

// //       {imageSrc && !cropped && (
// //         <div>
// //           <div
// //             style={{
// //               position: 'relative',
// //               width: '100%',
// //               height: '30vh',
// //               background: '#333',
// //             }}
// //             onWheel={(e) => {
// //               e.preventDefault();
// //               const wheelDelta = e.deltaY;
// //               let newZoom = zoom - wheelDelta / 500;
// //               newZoom = Math.max(1, newZoom);
// //               newZoom = Math.min(5, newZoom);
// //               setZoom(newZoom);
// //             }}
// //           >
// //             <Cropper
// //               image={imageSrc}
// //               crop={crop}
// //               zoom={zoom}
// //               aspect={1} // You can adjust the aspect ratio as needed
// //               onCropChange={setCrop}
// //               onCropComplete={onCropComplete}
// //               onZoomChange={setZoom}
// //             />
// //           </div>
// //           <div className="flex space-x-2 mt-2">
// //             <Button type="button" onClick={acceptCrop}>
// //               {t('acceptCrop')}
// //             </Button>
// //             <Button type="button" variant="outline" onClick={resetImage}>
// //               {t('cancel')}
// //             </Button>
// //           </div>
// //         </div>
// //       )}

// //       {cropped && croppedImageUrl && (
// //         <div className="space-y-2">
// //           <div className="flex justify-center">
// //             <img
// //               src={croppedImageUrl}
// //               alt="Cropped"
// //               className="w-32 h-32 object-cover rounded-full"
// //             />
// //           </div>
// //           <Button type="button" variant="outline" onClick={resetImage}>
// //             {t('uploadNewPhoto')}
// //           </Button>
// //         </div>
// //       )}
// //     </div>
// //   );
// // }

// // export default PhotoUpload;

// // File: src/components/PhotoUpload.js
// import React, { useState } from 'react';
// import Cropper from 'react-easy-crop';
// import { Button } from './ui/button';
// import { FiUpload } from 'react-icons/fi';

// function PhotoUpload({ onPhotoCropped, t }) {
//   const [imageSrc, setImageSrc] = useState(null);
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
//   const [zoom, setZoom] = useState(1);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [croppedImageUrl, setCroppedImageUrl] = useState(null);
//   const [cropped, setCropped] = useState(false);

//   const onFileChange = async (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       const fileReader = new FileReader();
//       fileReader.onload = () => {
//         setImageSrc(fileReader.result);
//         setCropped(false);
//         setZoom(1);
//       };
//       fileReader.readAsDataURL(e.target.files[0]);
//     }
//   };

//   const onCropComplete = (croppedArea, croppedAreaPixels) => {
//     setCroppedAreaPixels(croppedAreaPixels);
//   };

//   const acceptCrop = async () => {
//     const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
//     setCroppedImageUrl(croppedImage);
//     setCropped(true);
//     onPhotoCropped(croppedImage); // Pass the cropped image back to the parent component
//   };

//   const getCroppedImg = (imageSrc, croppedAreaPixels) => {
//     return new Promise((resolve, reject) => {
//       const image = new Image();
//       image.src = imageSrc;
//       image.crossOrigin = 'anonymous';
//       image.onload = () => {
//         const canvas = document.createElement('canvas');

//         // Calculate aspect ratio
//         const aspectRatio = croppedAreaPixels.width / croppedAreaPixels.height;

//         // Set max dimensions
//         let outputWidth = croppedAreaPixels.width;
//         let outputHeight = croppedAreaPixels.height;

//         if (outputWidth > 300 || outputHeight > 300) {
//           if (aspectRatio > 1) {
//             // Width is greater than height
//             outputWidth = 300;
//             outputHeight = 300 / aspectRatio;
//           } else {
//             // Height is greater than width
//             outputHeight = 300;
//             outputWidth = 300 * aspectRatio;
//           }
//         }

//         canvas.width = outputWidth;
//         canvas.height = outputHeight;
//         const ctx = canvas.getContext('2d');

//         ctx.drawImage(
//           image,
//           croppedAreaPixels.x,
//           croppedAreaPixels.y,
//           croppedAreaPixels.width,
//           croppedAreaPixels.height,
//           0,
//           0,
//           outputWidth,
//           outputHeight
//         );

//         const base64Image = canvas.toDataURL('image/png');
//         resolve(base64Image);
//       };
//       image.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };

//   const resetImage = () => {
//     setImageSrc(null);
//     setCroppedAreaPixels(null);
//     setZoom(1);
//     setCrop({ x: 0, y: 0 });
//     setCroppedImageUrl(null);
//     setCropped(false);
//   };

//   return (
//     <div className="space-y-4">
//       {!imageSrc && (
//         <div>
//           <label htmlFor="photo-upload" className="flex items-center cursor-pointer">
//             <FiUpload className="mr-2" />
//             <span>{t('uploadPhoto')}</span>
//           </label>
//           <input
//             type="file"
//             accept="image/*"
//             id="photo-upload"
//             onChange={onFileChange}
//             style={{ display: 'none' }}
//           />
//         </div>
//       )}

//       {imageSrc && !cropped && (
//         <div className="relative">
//           <div
//             className="relative w-full h-40 bg-gray-800 rounded-lg overflow-hidden"
//             onWheel={(e) => {
//               e.preventDefault();
//               const wheelDelta = e.deltaY;
//               let newZoom = zoom - wheelDelta / 500;
//               newZoom = Math.max(1, newZoom);
//               newZoom = Math.min(5, newZoom);
//               setZoom(newZoom);
//             }}
//           >
//             <Cropper
//               image={imageSrc}
//               crop={crop}
//               zoom={zoom}
//               aspect={1}
//               onCropChange={setCrop}
//               onCropComplete={onCropComplete}
//               onZoomChange={setZoom}
//             />
//           </div>
//           <div className="flex space-x-2 mt-2 justify-center">
//             <Button type="button" onClick={acceptCrop} className="flex-1">
//               {t('acceptCrop')}
//             </Button>
//             <Button type="button" variant="outline" onClick={resetImage} className="flex-1">
//               {t('cancel')}
//             </Button>
//           </div>
//         </div>
//       )}

//       {cropped && croppedImageUrl && (
//         <div className="space-y-2">
//           {/* <div className="flex justify-center">
//             <img
//               src={croppedImageUrl}
//               alt="Cropped"
//               className="w-32 h-32 object-cover rounded-full"
//             />
//           </div> */}
//           <Button type="button" variant="outline" onClick={resetImage} className="w-full">
//             {t('uploadNewPhoto')}
//           </Button>
//         </div>
//       )}
//     </div>
//   );
// }

// export default PhotoUpload;
// File: src/components/PhotoUpload.js
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button } from './ui/button';
import { FiUpload } from 'react-icons/fi';

function PhotoUpload({ onPhotoCropped, t }) {
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [cropped, setCropped] = useState(false);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImageSrc(fileReader.result);
        setCropped(false);
        setZoom(1);
      };
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const acceptCrop = async () => {
    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    setCroppedImageUrl(croppedImage);
    setCropped(true);
    onPhotoCropped(croppedImage); // Pass the cropped image back to the parent component
  };

  const getCroppedImg = (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');

        // Calculate aspect ratio
        const aspectRatio = croppedAreaPixels.width / croppedAreaPixels.height;

        // Set max dimensions
        let outputWidth = croppedAreaPixels.width;
        let outputHeight = croppedAreaPixels.height;

        if (outputWidth > 500 || outputHeight > 500) { // Increased max dimensions
          if (aspectRatio > 1) {
            // Width is greater than height
            outputWidth = 500;
            outputHeight = 500 / aspectRatio;
          } else {
            // Height is greater than width
            outputHeight = 500;
            outputWidth = 500 * aspectRatio;
          }
        }

        canvas.width = outputWidth;
        canvas.height = outputHeight;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          outputWidth,
          outputHeight
        );

        const base64Image = canvas.toDataURL('image/png');
        resolve(base64Image);
      };
      image.onerror = (error) => {
        reject(error);
      };
    });
  };

  const resetImage = () => {
    setImageSrc(null);
    setCroppedAreaPixels(null);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setCroppedImageUrl(null);
    setCropped(false);
  };

  return (
    <div className="space-y-4">
      {!imageSrc && (
        <div>
          <label htmlFor="photo-upload" className="flex items-center cursor-pointer">
            <FiUpload className="mr-2" />
            <span>{t('uploadPhoto')}</span>
          </label>
          <input
            type="file"
            accept="image/*"
            id="photo-upload"
            onChange={onFileChange}
            style={{ display: 'none' }}
            aria-label={t('uploadPhoto')}
          />
        </div>
      )}

      {imageSrc && !cropped && (
        <div className="relative">
          <div
            className="relative w-full h-56 bg-gray-800 rounded-lg overflow-hidden"
            onWheel={(e) => {
              e.preventDefault();
              const wheelDelta = e.deltaY;
              let newZoom = zoom - wheelDelta / 500;
              newZoom = Math.max(1, newZoom);
              newZoom = Math.min(5, newZoom);
              setZoom(newZoom);
            }}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="flex space-x-2 mt-2 justify-center">
            <Button type="button" onClick={acceptCrop} className="flex-1">
              {t('acceptCrop')}
            </Button>
            <Button type="button" variant="outline" onClick={resetImage} className="flex-1">
              {t('cancel')}
            </Button>
          </div>
        </div>
      )}

      {cropped && croppedImageUrl && (
        <div className="space-y-2">
          {/* <div className="flex justify-center">
            <img
              src={croppedImageUrl}
              alt="Cropped"
              className="w-40 h-40 object-cover rounded-lg"
            />
          </div> */}
          <Button type="button" variant="outline" onClick={resetImage} className="w-full">
            {t('uploadNewPhoto')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default PhotoUpload;
