// src/components/WelcomeCard.js
import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/ui/table';
import api from '../api';
import { Eye } from 'lucide-react';
import ReceiptCard from './ReceiptCard';

const WelcomeCard = ({ t, onSubscribe }) => {
  const navigate = useNavigate();
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showReceipt, setShowReceipt] = useState(false);
  const [selectedSubscriber, setSelectedSubscriber] = useState(null);
  const [showSubscribersList, setShowSubscribersList] = useState(false);

  const fetchSubscribers = async () => {
    try {
      setLoading(true);
      const response = await api.getSubscribers();
      setSubscribers(response.data);
    } catch (error) {
      setError(api.handleError(error));
    } finally {
      setLoading(false);
    }
  };
  
  const handleViewReceipt = async (subscriber) => {
    try {
      setLoading(true);
      const response = await api.getPaymentReceipt(subscriber.payment_id);
      setSelectedSubscriber(response.data);
      setShowReceipt(true);
    } catch (error) {
      setError(api.handleError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setShowReceipt(false);
    setSelectedSubscriber(null);
  };

  if (showReceipt && selectedSubscriber) {
    return (
      <div>
        <Button onClick={handleBack} className="mb-4">
          {t('backToList')}
        </Button>
        <ReceiptCard receiptData={selectedSubscriber?.data} t={t} />
      </div>
    );
  }

  const SubscribersList = () => (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <h2 className="text-2xl font-bold">{t('subscribersList')}</h2>
        <Button onClick={() => setShowSubscribersList(false)}>
          {t('back')}
        </Button>
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="text-center py-4">{t('loading')}...</div>
        ) : error ? (
          <div className="text-red-500 text-center py-4">{error}</div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('name')}</TableHead>
                <TableHead>{t('subscriptionType')}</TableHead>
                <TableHead>{t('dateJoined')}</TableHead>
                <TableHead>{t('status')}</TableHead>
                <TableHead>{t('actions')}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {subscribers.map((subscriber) => (
                <TableRow key={subscriber.id}>
                  <TableCell>
                    {subscriber.surname} {subscriber.name}
                  </TableCell>
                  <TableCell>{t(subscriber.subscription_type)}</TableCell>
                  <TableCell>
                    {new Date(subscriber.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{subscriber.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleViewReceipt(subscriber)}
                    >
                      <Eye className="h-4 w-4 mr-2" />
                      {t('viewReceipt')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );

  return (
    <>
      {showSubscribersList ? (
        <SubscribersList />
      ) : (
        <Card>
          <CardHeader>
            <h2 className="text-2xl font-bold">{t('welcome')}</h2>
          </CardHeader>
          <CardContent>
            <p className="mb-4">{t('joinMessage')}</p>
            <div className="buttons-container flex space-x-4">
              <Button onClick={onSubscribe}>{t('subscribe')}</Button>
              <Button
                onClick={() => {
                  setShowSubscribersList(true);
                  fetchSubscribers();
                }}
              >
                {t('viewSubscribers')}
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default WelcomeCard;