// SubscriptionForm.js
import React, { useState,useEffect } from 'react';
import { Card, CardHeader, CardContent } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { AlertTriangle } from 'lucide-react';
import FormField from './FormField';
import StateSelector from './StateSelector';

import DistrictSelector from './DistrictSelector';
import MandalSelector from './MandalSelector';
import SubscriptionTypeSelector from './SubscriptionTypeSelector';
import SubscriptionOptionsSelector from './SubscriptionOptionsSelector';
import PhotoUpload from './PhotoUpload';
import ReceiptCard from './ReceiptCard'; // New ReceiptCard Component
import api from '../api';

const getSubscriptionAmount = (type) => {
  const amounts = {
    'maharajaPoshakulu': 100000,
    'rajaPoshakulu': 50000,
    'saswataSabhyatvam': 100,
    'yearlySubscription': 10,
    'sadharanaSabyatvam': 10
  };
  return amounts[type] || 0;
};

const SubscriptionSummary = ({ formData, onBack, onConfirm, loading, error, t }) => {
  return (
    <Card>
      <CardHeader>
        <h2 className="text-2xl font-bold text-center">{t('subscriptionSummary')}</h2>
      </CardHeader>
      <CardContent>
        {error && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 rounded flex items-center">
            <AlertTriangle className="w-5 h-5 text-red-500 mr-2" />
            <span className="text-red-700">{error}</span>
          </div>
        )}

        <div className="space-y-4">
          {formData.photo && (
            <div className="flex justify-center">
              <img 
                src={formData.photo} 
                alt="Profile" 
                className="w-32 h-32 object-cover rounded-full"
              />
            </div>
          )}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-semibold">{t('name')}:</h3>
              <p>{formData.surname} {formData.name}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('sOrWifeOrDaughterOf')}:</h3>
              <p>{formData.sOrWifeOrDaughterOf}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('phone')}:</h3>
              <p>{formData.phone}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('email')}:</h3>
              <p>{formData.email}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('dateOfBirth')}:</h3>
              <p>{formData.dateOfBirth}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('gotra')}:</h3>
              <p>{formData.gotra}</p>
            </div>
            {/* New Fields */}
            <div>
              <h3 className="font-semibold">{t('sex')}:</h3>
              <p>{t(formData.sex)}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('marriageStatus')}:</h3>
              <p>{t(formData.marriage_status)}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('bloodGroup')}:</h3>
              <p>{formData.blood_group}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('education')}:</h3>
              <p>{formData.education}</p>
            </div>
            <div>
              <h3 className="font-semibold">{t('occupation')}:</h3>
              <p>{formData.occupation}</p>
            </div>
            <div className="col-span-2">
              <h3 className="font-semibold">{t('address')}:</h3>
              <p>{formData.houseNumber}, {formData.streetName}</p>
              <p>{formData.mandal}, {formData.district}</p>
              <p>{formData.state} - {formData.pinCode}</p>
            </div>
          </div>

          <div className="mt-6 p-4 bg-blue-50 rounded">
            <h3 className="font-semibold">{t('subscriptionDetails')}:</h3>
            <p className="text-lg">{t('type')}: {t(formData.subscriptionOption)}</p>
            <p className="text-lg">{t('amount')}: ₹{getSubscriptionAmount(formData.subscriptionOption)}</p>
          </div>

          <div className="flex space-x-4">
            <Button 
              onClick={onBack}
              variant="outline"
              className="flex-1"
            >
              {t('back')}
            </Button>
            <Button
              onClick={onConfirm}
              disabled={loading}
              className="flex-1"
            >
              {loading ? t('processing') : t('proceedToPayment')}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const SubscriptionForm = ({ t }) => {
  const dummyPerson = {
    surname: 'Nalamara',
    name: 'Arun',
    sOrWifeOrDaughterOf: 'Anantha Lakshmaiah',
    dateOfBirth: '1990-01-01',
    phone: '8800197778',
    email: 'ab@ameyem.com',
    gotra: 'Vamana rushi Gotra',
    houseNumber: '123',
    streetName: 'Test Street',
    mandal: 'Test Mandal',
    district: 'Test District',
    state: 'Telangana',
    pinCode: '500001',
    subscriptionOption: 'sadharanaSabyatvam', // default subscription type
    photo: null,
    // New Fields
    sex: 'male', // or 'female', 'other'
    marriage_status: 'single', // 'married', 'divorced', etc.
    blood_group: 'O+', // e.g., 'A+', 'B-', etc.
    education: 'Bachelor\'s Degree',
    occupation: 'Engineer',
  };
  const initialFormState = {
    surname: '',
    name: '',
    sOrWifeOrDaughterOf: '',
    dateOfBirth: '',
    phone: '',
    email: '',
    gotra: '',
    houseNumber: '',
    streetName: '',
    mandal: '',
    district: '',
    state: '',
    pinCode: '',
    subscriptionOption: 'sadharanaSabyatvam', // default subscription type
    photo: null,
    sex: '',
    marriage_status: '',
    blood_group: '',
    education: '',
    occupation: '',
  };

  const [paymentDone, setPaymentDone] = useState(false);
  // const [formData, setFormData] = useState(dummyPerson);
  const [formData, setFormData] = useState(initialFormState);
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedMandal, setSelectedMandal] = useState('');
  const [stateName, setStateName] = useState(''); // Add this to store state name

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('subscription');
  const [showSummary, setShowSummary] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [receiptData, setReceiptData] = useState(null); // New state for ReceiptCard
  const [addressDetails, setAddressDetails] = useState(null);
  // const [formData, setFormData] = useState(initialFormState);
  

  // Add this effect to update formData when locations are selected
  // useEffect(() => {
  //   console.log('Selected state changed:', selectedState);
  // }, [selectedState]);
  useEffect(() => {
    // Load formData from localStorage on component mount
    const savedFormData = localStorage.getItem('subscriptionFormData');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);
  useEffect(() => {
    // Save formData to localStorage whenever it changes
    localStorage.setItem('subscriptionFormData', JSON.stringify(formData));
  }, [formData]);



  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePhotoCropped = (croppedImage) => {
    setFormData({ ...formData, photo: croppedImage });
  };

  const handlePhotoChange = (croppedImage) => {
    setFormData({ ...formData, photo: croppedImage });
  };

  const handleSubscriptionTypeChange = (newType) => {
    setSubscriptionType(newType);
    setFormData((prevData) => ({ ...prevData, subscriptionOption: '' }));
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

 


  
  const handlePaymentVerification = async (razorpayResponse, orderData) => {
    try {
      console.log('Verifying payment with data:', {
        razorpayResponse,
        orderData
      });

      // Create verification payload
      const verificationData = {
        razorpay_payment_id: razorpayResponse.razorpay_payment_id,
        razorpay_order_id: razorpayResponse.razorpay_order_id,
        razorpay_signature: razorpayResponse.razorpay_signature
      };

      console.log('Sending verification request with:', verificationData);

      const verifyResponse = await api.raw.post('/api/payments/verify', verificationData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      console.log('Verification Response:', verifyResponse.data);

      if (verifyResponse.data?.success) {
        setPaymentSuccess(true);
        setReceiptData(          verifyResponse.data        );
      } else {
        throw new Error(verifyResponse.data?.message || 'Payment verification failed');
      }
    } catch (error) {
      console.error('Payment verification error:', error);
      setError(error.response?.data?.message || error.message || 'Payment verification failed');
    } finally {
      setLoading(false);
    }
  };


  const handlePayment = async () => {
      try {
          setLoading(true);
          setError('');

          // Initialize Razorpay SDK
          const res = await initializeRazorpay();
          if (!res) {
              throw new Error('Razorpay SDK failed to load');
          }
          const mandal_id = selectedMandal ? parseInt(selectedMandal, 10) : null;
          const { mandal, district, state, ...personData } = formData;
          // Create order
          const orderResponse = await api.raw.post('/api/payments/create-order', {
              amount: getSubscriptionAmount(formData.subscriptionOption),
              purpose: 'subscription',
              subscription_type: formData.subscriptionOption,
              person_data: {
                  ...personData,
                  mandal_id: mandal_id, // Add mandal_id
                  // user_id: 1, // Set user_id as 1
                  // addressDetails, // Include address details
                  photo: formData.photo
              }
          });

          console.log('Order Response:', orderResponse.data);

          if (!orderResponse.data?.success) {
              throw new Error(orderResponse.data?.message || 'Failed to create order');
          }

          // Configure Razorpay options
          const options = {
              key: orderResponse.data.key,
              amount: orderResponse.data.amount, // Ensure this is a string
              currency: 'INR',
              order_id: orderResponse.data.order_id,
              name: "Thogata Veera Kshatriya Sangham",
              description: "Membership Subscription",
              prefill: {
                  name: `${formData.surname} ${formData.name}`,
                  email: formData.email,
                  contact: formData.phone
              },
              notes: {
                  address: `${formData.houseNumber}, ${formData.streetName}`,
              },
              handler: function(response) {
                  // This handler will only be called for standard payments
                  console.log('Razorpay Response:', response);
                  // Validate response fields
                  if (!response.razorpay_payment_id || !response.razorpay_order_id || !response.razorpay_signature) {
                      console.error('Missing required Razorpay response fields:', response);
                      setError('Invalid payment response from Razorpay');
                      setLoading(false);
                      return;
                  }
                  handlePaymentVerification(response, orderResponse.data);
              },
              modal: {
                  ondismiss: function() {
                      setLoading(false);
                  }
              },
              theme: {
                  color: "#3399cc"
              }
          };

          console.log('Razorpay Options:', options);

          const rzp = new window.Razorpay(options);
          
          // Handle payment failure
          rzp.on('payment.failed', function(failedResponse) {
              console.error('Payment Failed:', failedResponse);
              setError(failedResponse.error?.description || 'Payment failed');
              setLoading(false);
          });

          // Handle external payments like QR Code
          rzp.on('payment.success', function(response) {
              // This may not be triggered for QR Code payments
              console.log('External Payment Success:', response);
              // Optionally, notify the user to check their payment status
              alert('Payment completed! Please wait for confirmation.');
              setLoading(false);
          });

          rzp.open();

      } catch (error) {
          console.error('Payment initiation error:', error);
          setError(error.response?.data?.message || error.message || 'Payment failed');
          setLoading(false);
      }
  };




  // Update the conditional rendering for ReceiptCard
  if (paymentSuccess && receiptData) {
    console.log('receiptData ',receiptData)
    const formattedReceiptData = {
      payment: receiptData.data.payment,
      message: receiptData.data.message
    };
    return <ReceiptCard receiptData={formattedReceiptData} t={t} />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Add validation for required fields
    const requiredFields = [
      'surname',
      'name',
      'sOrWifeOrDaughterOf',
      'phone',
      'email',
      'dateOfBirth',
      'streetName',
      'houseNumber',
      'pinCode',
      'subscriptionOption',
      'sex',
      'marriage_status',
      'blood_group',
      'education',
      'occupation'
    ];

    const missingFields = requiredFields.filter(field => !formData[field]);

    if (missingFields.length > 0) {
      setError(t('pleaseCompleteAllRequiredFields') || 'Please complete all required fields');
      return;
    }

    // If all required fields are present, show the summary
    setError('');
    setShowSummary(true);
  };


  if (showSummary) {
    return (
      <SubscriptionSummary
        formData={{
          ...formData,
          mandal: selectedMandal,
          district: selectedDistrict,
          state: selectedState,
        }}
        onBack={() => setShowSummary(false)}
        onConfirm={handlePayment}
        loading={loading}
        error={error}
        t={t}
      />
    );
  }



  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <Card className="max-w-4xl mx-auto bg-white shadow-lg">
        <CardHeader className="border-b border-gray-200 bg-gray-50">
          <h2 className="text-2xl font-bold text-gray-900">{t('subscribe')}</h2>
        </CardHeader>
        <CardContent className="pt-6">
        <form onSubmit={handleSubmit} className="space-y-6">
            {error && (
              <div className="rounded-md bg-red-50 p-4 border border-red-200">
                <div className="flex">
                  <AlertTriangle className="h-5 w-5 text-red-400" />
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{error}</h3>
                  </div>
                </div>
              </div>
            )}

             {/* Photo Upload Section */}
            <div className="bg-gray-50 p-4 rounded-lg flex justify-center">
              <div className="w-56 h-56 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center relative">
                {formData.photo ? (
                  <img 
                    src={formData.photo} 
                    alt="Uploaded Profile" 
                    className="w-full h-full object-cover rounded-lg"
                  />
                ) : (
                  <span className="text-gray-400 text-sm">
                    {t('uploadPassportPhoto')}
                  </span>
                )}
                <PhotoUpload onPhotoCropped={handlePhotoCropped} t={t} />
              </div>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg grid grid-cols-1 md:grid-cols-3 gap-4">
              <StateSelector 
                selectedState={selectedState}
                setSelectedState={setSelectedState}
              />
              <DistrictSelector
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
                setSelectedDistrict={setSelectedDistrict}
              />
              <MandalSelector
                selectedDistrict={selectedDistrict}
                selectedMandal={selectedMandal}
                setSelectedMandal={setSelectedMandal}
              />
            </div>

            {/* Second Section: Village/Street Name, H No, Pin Code */}
            <div className="bg-gray-50 p-4 rounded-lg grid grid-cols-1 md:grid-cols-3 gap-4">
              <FormField
                name="streetName"
                label={t('streetName')}
                value={formData.streetName}
                onChange={handleInputChange}
              />
              <FormField
                name="houseNumber"
                label={t('houseNumber')}
                value={formData.houseNumber}
                onChange={handleInputChange}
              />
              <FormField
                name="pinCode"
                label={t('pinCode')}
                value={formData.pinCode}
                onChange={handleInputChange}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
              
              
              <FormField
                name="surname"
                label={t('surname')}
                value={formData.surname}
                onChange={handleInputChange}
              />
              <FormField
                name="name"
                label={t('name')}
                value={formData.name}
                onChange={handleInputChange}
              />
              <FormField
                name="sOrWifeOrDaughterOf"
                label={t('sOrWifeOrDaughterOf')}
                value={formData.sOrWifeOrDaughterOf}
                onChange={handleInputChange}
              />
              <FormField
                name="gotra"
                label={t('gotra')}
                value={formData.gotra}
                onChange={handleInputChange}
              />
              <FormField
                name="dateOfBirth"
                label={t('dateOfBirth')}
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                type="date"
              />
              <FormField
                name="phone"
                label={t('phone')}
                value={formData.phone}
                onChange={handleInputChange}
                type="tel"
              />
              <FormField
                name="email"
                label={t('email')}
                value={formData.email}
                onChange={handleInputChange}
                type="email"
              />
              <FormField
                name="sex"
                label={t('sex')}
                value={formData.sex}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: 'male', label: t('male') },
                  { value: 'female', label: t('female') },
                  { value: 'other', label: t('other') },
                ]}
              />
              <FormField
                name="marriage_status"
                label={t('marriageStatus')}
                value={formData.marriage_status}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: 'single', label: t('single') },
                  { value: 'married', label: t('married') },
                  { value: 'divorced', label: t('divorced') },
                  { value: 'widowed', label: t('widowed') },
                ]}
              />
              <FormField
                name="blood_group"
                label={t('bloodGroup')}
                value={formData.blood_group}
                onChange={handleInputChange}
                type="select"
                options={[
                  { value: 'A+', label: 'A+' },
                  { value: 'A-', label: 'A-' },
                  { value: 'B+', label: 'B+' },
                  { value: 'B-', label: 'B-' },
                  { value: 'AB+', label: 'AB+' },
                  { value: 'AB-', label: 'AB-' },
                  { value: 'O+', label: 'O+' },
                  { value: 'O-', label: 'O-' },
                ]}
              />
              <FormField
                name="education"
                label={t('education')}
                value={formData.education}
                onChange={handleInputChange}
              />
              <FormField
                name="occupation"
                label={t('occupation')}
                value={formData.occupation}
                onChange={handleInputChange}
              />
            </div>

            <div className="bg-gray-50 p-4 rounded-lg">
              <SubscriptionTypeSelector
                t={t}
                subscriptionType={subscriptionType}
                setSubscriptionType={handleSubscriptionTypeChange}
              />
              <SubscriptionOptionsSelector
                t={t}
                subscriptionType={subscriptionType}
                formData={formData}
                setFormData={setFormData}
              />
            </div>

            <Button 
              type="submit" 
              className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 rounded-md transition-colors"
            >
              {t('submit')}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};



export default SubscriptionForm;
