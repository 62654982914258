// src/components/Policies/ReturnPolicy.js
import React from 'react';

const ReturnPolicy = () => {
  return (
    <div className="policy-page p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Return Policy</h1>
      <p className="mb-4">
        We offer refund/exchange within the first 1 day from the date of your purchase. If 1 day has passed since your purchase, you will not be offered a return, exchange, or refund of any kind. In order to become eligible for a return or an exchange:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>The purchased item should be unused and in the same condition as you received it.</li>
        <li>The item must have original packaging.</li>
        <li>If the item that you purchased is on sale, then the item may not be eligible for a return/exchange.</li>
      </ul>
      <p className="mb-4">
        Further, only such items are replaced by us (based on an exchange request) if such items are found defective or damaged.
      </p>
      <p className="mb-4">
        You agree that there may be a certain category of products/items that are exempted from returns or refunds. Such categories of the products would be identified to you at the item of purchase. For exchange/return accepted request(s) (as applicable), once your returned product/item is received and inspected by us, we will send you an email to notify you about receipt of the returned/exchanged product. Further, if the same has been approved after the quality check at our end, your request (i.e., return/exchange) will be processed in accordance with our policies.
      </p>
    </div>
  );
};

export default ReturnPolicy;
