
// src/api.js
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.thogata.co.in';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true
});

// Add error interceptor
api.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', error.response || error);
    return Promise.reject(error);
  }
);

// Location related endpoints
const locations = {
  getStates: () => api.get('/api/states'),
  getDistricts: (stateId) => api.get('/api/districts', { params: { state_id: stateId } }),
  getMandals: (districtId) => api.get('/api/mandals', { params: { district_id: districtId } })
};

// Payment related endpoints
const payments = {
  createOrder: (data) => api.post('/api/payments/create-order', data),
  verify: (data) => api.post('/api/payments/verify', data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }),
  handleFailure: (data) => api.post('/api/payments/payment-failed', data),
  getReceipt: (id) => api.get(`/api/payments/${id}`)
};
// Subscriber related endpoints
const subscribers = {
  getAll: () => api.get('/api/subscribers'),
  getOne: (id) => api.get(`/api/subscribers/${id}`),
  create: (data) => api.post('/api/subscribers', data)
};

const apiClient = {
  // Location methods
  getStates: locations.getStates,
  getDistricts: locations.getDistricts,
  getMandals: locations.getMandals,

  // Payment methods
  createOrder: payments.createOrder,
  verifyPayment: payments.verify,
  handlePaymentFailure: payments.handleFailure,
  getPaymentReceipt: payments.getReceipt,

  // Subscriber methods
  getSubscribers: subscribers.getAll,
  getSubscriber: subscribers.getOne,
  createSubscriber: subscribers.create,

  // Helper method to handle errors
  handleError: (error) => {
    const message = error.response?.data?.message || error.message || 'An error occurred';
    console.error('API Error:', error);
    return Promise.reject(message);
  },

  // Raw axios instance for direct use
  raw: api
};

export default apiClient;