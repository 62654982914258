

// export default App;
// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer'; // Import the Footer component
import LanguageSelector from './components/LanguageSelector';
import WelcomeCard from './components/WelcomeCard';
import SubscriptionForm from './components/SubscriptionForm';
import Donate from './components/SimpleDonate'; // Ensure Donate component exists
import IDCardTest from './components/IDCardTest';
import { useTranslation } from './hooks/useTranslation';

import './App.css';

// Import Policy Components
import TermsAndConditions from './components/Policies/TermsAndConditions';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import RefundAndCancellationPolicy from './components/Policies/RefundAndCancellationPolicy';
import ReturnPolicy from './components/Policies/ReturnPolicy';
import ShippingPolicy from './components/Policies/ShippingPolicy';
import ReceiptCard from './components/ReceiptCard';

// function App() {
//   return (
//     <div className="App">
//       <Donate />
//     </div>
//   );
// }

const App = () => {
  const [lang, setLang] = useState('te');
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation(lang);

  const handleLanguageChange = (newLang) => {
    setLang(newLang);
  };

  return (
    <Router>
      <MainContent
        lang={lang}
        handleLanguageChange={handleLanguageChange}
        showForm={showForm}
        setShowForm={setShowForm}
        t={t}
      />
    </Router>
  );
};

const MainContent = ({ lang, handleLanguageChange, showForm, setShowForm, t }) => {
  const location = useLocation();
  const isMainPage = location.pathname === '/';

  return (
    <div className="app-container flex flex-col min-h-screen">
      {/* Header */}
      <Header title={t('title')} lang={lang} setLang={handleLanguageChange} />
      
      {/* Main Content */}
      <div className="content-container flex-grow">
        <Routes>
          <Route
            path="/"
            element={
              !showForm ? (
                <WelcomeCard t={t} onSubscribe={() => setShowForm(true)} />
              ) : (
                <SubscriptionForm t={t} />
              )
            }
          />
          <Route path="/donate" element={<Donate />} />
          {/* Policy Routes */}
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/refund-cancellation" element={<RefundAndCancellationPolicy />} />
          <Route path="/return" element={<ReturnPolicy />} />
          <Route path="/shipping" element={<ShippingPolicy />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
      
      {/* Conditional Footer */}
      {isMainPage && <Footer />}
    </div>
  );
};

export default App;
